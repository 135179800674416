import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import withWindow from "../helpers/withWindow"

const Article = ({ data: { markdownRemark: post } }) => {
  return (
    <>
      <Helmet>
        <title>{post.frontmatter.title}</title>
      </Helmet>
      <article className="article">
        <h1>{post.frontmatter.title}</h1>

        <div
          className="[ flow ] [ article-content ]"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </article>
    </>
  )
}

export default withWindow(Article)

export const postQuery = graphql`
  query ArticleByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`
